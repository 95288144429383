import { default as indexgzXQpNtl6iMeta } from "/home/frontend/simplecheatsheet/pages/[topic]/[post]/index.vue?macro=true";
import { default as indexu5yIrIQEQrMeta } from "/home/frontend/simplecheatsheet/pages/[topic]/index.vue?macro=true";
import { default as _91id_string_933F3xiXDHNgMeta } from "/home/frontend/simplecheatsheet/pages/category/[id_string].vue?macro=true";
import { default as helloxVWtnTDMdrMeta } from "/home/frontend/simplecheatsheet/pages/hello.vue?macro=true";
import { default as index34yQL9ycuMMeta } from "/home/frontend/simplecheatsheet/pages/index.vue?macro=true";
import { default as submitxwSjxf779IMeta } from "/home/frontend/simplecheatsheet/pages/submit.vue?macro=true";
import { default as _91id_string_93eZOUsZEgO1Meta } from "/home/frontend/simplecheatsheet/pages/tag/[id_string].vue?macro=true";
export default [
  {
    name: "topic-post",
    path: "/:topic()/:post()",
    component: () => import("/home/frontend/simplecheatsheet/pages/[topic]/[post]/index.vue").then(m => m.default || m)
  },
  {
    name: "topic",
    path: "/:topic()",
    component: () => import("/home/frontend/simplecheatsheet/pages/[topic]/index.vue").then(m => m.default || m)
  },
  {
    name: "category-id_string",
    path: "/category/:id_string()",
    component: () => import("/home/frontend/simplecheatsheet/pages/category/[id_string].vue").then(m => m.default || m)
  },
  {
    name: "hello",
    path: "/hello",
    component: () => import("/home/frontend/simplecheatsheet/pages/hello.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/frontend/simplecheatsheet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "submit",
    path: "/submit",
    component: () => import("/home/frontend/simplecheatsheet/pages/submit.vue").then(m => m.default || m)
  },
  {
    name: "tag-id_string",
    path: "/tag/:id_string()",
    component: () => import("/home/frontend/simplecheatsheet/pages/tag/[id_string].vue").then(m => m.default || m)
  }
]