import revive_payload_client_aPloggZpr4 from "/home/frontend/simplecheatsheet/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_uraf42bsqcnjzfop5csglseihi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_58E8tpQuf9 from "/home/frontend/simplecheatsheet/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_uraf42bsqcnjzfop5csglseihi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MiiZ4mN7fV from "/home/frontend/simplecheatsheet/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_uraf42bsqcnjzfop5csglseihi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_Ljsm63i3mb from "/home/frontend/simplecheatsheet/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_uraf42bsqcnjzfop5csglseihi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_FbzEpD7hsQ from "/home/frontend/simplecheatsheet/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_uraf42bsqcnjzfop5csglseihi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_E9wMWPZEwp from "/home/frontend/simplecheatsheet/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_uraf42bsqcnjzfop5csglseihi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_M5nXWzb29V from "/home/frontend/simplecheatsheet/node_modules/.pnpm/@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.19.2_typescript@5.5.4_vue@3.4.35_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/frontend/simplecheatsheet/.nuxt/components.plugin.mjs";
import prefetch_client_ddEZRIgCmd from "/home/frontend/simplecheatsheet/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.0.3_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_uraf42bsqcnjzfop5csglseihi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_CMjGkoxbu8 from "/home/frontend/simplecheatsheet/node_modules/.pnpm/nuxt-lazy-load@3.0.4_magicast@0.3.4_rollup@4.19.2/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import plugin_client_UCeAz8jLwE from "/home/frontend/simplecheatsheet/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.19.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/home/frontend/simplecheatsheet/.nuxt/unocss.mjs";
import auth_vT9JWWT9pN from "/home/frontend/simplecheatsheet/plugins/auth.ts";
import error_handler_kEP5FliEXj from "/home/frontend/simplecheatsheet/plugins/error-handler.ts";
import socialShare_client_u4XrwCs4bM from "/home/frontend/simplecheatsheet/plugins/socialShare.client.ts";
import touch_client_hyaPpzYrWA from "/home/frontend/simplecheatsheet/plugins/touch.client.ts";
import touch_5RFx4YnLk6 from "/home/frontend/simplecheatsheet/plugins/touch.ts";
export default [
  revive_payload_client_aPloggZpr4,
  unhead_58E8tpQuf9,
  router_MiiZ4mN7fV,
  navigation_repaint_client_Ljsm63i3mb,
  check_outdated_build_client_FbzEpD7hsQ,
  chunk_reload_client_E9wMWPZEwp,
  plugin_vue3_M5nXWzb29V,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ddEZRIgCmd,
  plugin_CMjGkoxbu8,
  plugin_client_UCeAz8jLwE,
  unocss_MzCDxu9LMj,
  auth_vT9JWWT9pN,
  error_handler_kEP5FliEXj,
  socialShare_client_u4XrwCs4bM,
  touch_client_hyaPpzYrWA,
  touch_5RFx4YnLk6
]